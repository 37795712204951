.impact-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  overflow: hidden;
}

.chips {
  margin-top: 1rem;
  overflow: hidden;
  padding: 0 2rem;
}
.chips li {
  list-style: none;
  border: 1px solid black;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  font-weight: 500;
  border-radius: 1.5rem;
  cursor: pointer;
  min-width: 100px;
}
.chips li.active {
  background: #000;
  color: #fff;
}
.chips ul {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  overflow-x: scroll;
  padding: 1rem;
  &::-webkit-scrollbar {
    display: none;
  }
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  gap: 1rem;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
.card {
  height: 320px;
  width: 200px;
  border-radius: 0.8rem;
  box-shadow: 1px 1px 9px 1px #cbcbcb;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.profile-img {
  border-radius: 0.8rem 0.8rem 0rem 0rem;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 70%;
}
.profile-content {
  padding: 1rem;
}
.profile-content p {
  font-size: 0.8rem;
}
.profile-content p:nth-child(3) {
  font-weight: 600;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .impact-section {
    width: 90vw;
    margin-top: 5rem;
  }
  .impact-section h1 {
    font-size: 1.4em;
  }
  .chips {
    margin-top: 0rem;
    overflow: hidden;
    padding: 0 0rem;
  }
  .chips ul {
    white-space: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
  }

  .card-container {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
