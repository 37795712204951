.SearchComponent {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .blog-globe {
        position: absolute;
        top: 91px;
        right: 425px;
        transform: rotate(-13deg);
    }

    .blog-globe img {
        max-width: 8em;
    }

    .globe {
        position: absolute;
        top: 1rem;
        right: 27rem;
        z-index: -3;
    }

    .globe img {
        width: 8em;
        transform: rotate(-13deg);
    }


}