.undermaintainance{
    width: 100vw;
    height: 100vh;
    margin: 4em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    object-fit: cover;
    overflow: hidden;
}

.image-and-heading img {
    max-width: 13vw;
  }

.image-and-heading h3{
    font-size: 3rem;
    font-weight: bold;
    color: #349367;
}

.image-and-heading p{
    font-size: 2rem;
}

.robot-img img{
    max-width: 40%;
}

.red-circle{
    width: 40rem;
    height: 40rem;
    background-color: #FF1E1E;
    border-radius: 50%;
    filter: blur(70px);
    opacity: 0.2;
    position: absolute;
    top: -10rem;
    left: -10rem;
    z-index: -1;
}

.green-circle{
    width: 40rem;
    height: 40rem;
    background-color: #349367;
    border-radius: 50%;
    filter: blur(70px);
    opacity: 0.2;
    position: absolute;
    bottom: -10rem;
    right: -10rem;
    z-index: -1;
}

@media (max-width: 850px) {
    .undermaintainance{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin: 1em;
    }
    .image-and-heading{
        width : 80%;
        height: 50%;
    }
    .image-and-heading img {
        max-width: 15em;
        margin:1em ;

      }
    
    .image-and-heading h3{
        font-size: 2rem;
        font-weight: bold;
        color: #349367;
    }
    
    .image-and-heading p{
        font-size: 1rem;
    }
    
    .robot-img img{
        max-width: 80%;
    }
    
    .red-circle{
        width: 40rem;
        height: 40rem;
        background-color: #FF1E1E;
        border-radius: 50%;
        filter: blur(70px);
        opacity: 0.2;
        position: absolute;
        top: -10rem;
        left: -10rem;
        z-index: -1;
    }
    
    .green-circle{
        width: 40rem;
        height: 40rem;
        background-color: #349367;
        border-radius: 50%;
        filter: blur(70px);
        opacity: 0.2;
        position: absolute;
        bottom: -10rem;
        right: -10rem;
        z-index: -1;
    }

}