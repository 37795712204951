/* Header.css */

.header {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.header-container {
  width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo{
  margin: 2em;
}
.header img {
  max-width: 10vw;
}

.logo h1 {
  margin: 0;
  font-size: 1.5rem;
  padding-left: 1em;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links ul {
  list-style: none;
  display: flex;
  align-items: center;
}

.nav-links li {
  margin: 1em;
  color: black;
  font-weight: bold;
  letter-spacing: 2px;
}

/* .nav-links a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  } */

.nav-links a:hover {
  /* text-decoration: underline; */
  color: #3fa959;
}

.Clicked {
  color: #3fa959;
}
.Clicked:hover {
  color: #ffffff;
}
.NotClicked {
  color: black;
}

a:link {
  text-decoration: none;
}

/* .responsive-nav {
  display: none;
} */

.mobile-view {
  visibility: hidden;
  font-size: 2rem;
}

.mobile-nav-links ul {
  position: fixed;
  width: 100vw;
  height: 20rem;
  background-color: lightblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  top: 3.7rem;
  left: 0;
  gap: 1rem;
  font-size: 1rem;
  cursor: pointer;
  z-index: 999;
}

/* Responsive layout */

@media (max-width: 850px) {
  .header {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
  }
  .header-container {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header img {
    max-width: 25vw;
  }
  .nav-links ul {
    display: none;
    border: 1px solid;
  }

  .mobile-view {
    visibility: visible !important;
    padding-right: 1rem;
    cursor: pointer;
  }

  .mobile-nav-links ul {
    font-family: raleway;
    position: fixed;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background-color: lightblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    top: -2rem;
    left: 0;
    /* gap: 2rem; */
    font-size: 1rem;
    cursor: pointer;
    z-index: 5555;
    font-size: 1.8rem;
  }

  .mobile-nav-links ul li {
    padding: 1rem 0;
    color: black;
  }

  .mobile-nav-links ul li:hover {
    background-color: #3fa959;
    width: 80vw;
    text-align: center;
    border-radius: 10px;
    transition: 0.5s;
  }

  img {
    max-width: 40vw;
  }
}
/* 
@media (max-width: 380px) {
  .header {
    border: 1px solid red;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    border: 1px solid;
  }

  .header-container {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-view {
    display: block !important;
  }

  .mobile-nav-links ul {
    border: 1px solid;
    position: fixed;
    width: 100vw;
    padding: 0;
    height: 20rem;
    background-color: lightblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    top: 1.7rem;
    left: 0;
    gap: 1rem;
    font-size: 1rem;
    cursor: pointer;
    z-index: 999;
  }
} */
