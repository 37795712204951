.Services{
    /* ServiceCard.css */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
.de-code {
  color: #f2f2f2;
  font-size: 6.8rem;
  font-weight: 700;
  opacity: 0;
  transform: scale(0);
  transition: transform 2s linear;
  transition-delay: .3s;
}

.de-code h1 {
  margin: 0;
}

.animate{
  /* color: grey; */
  opacity: 1;
  transform: scale(1);
}


.title2 p {
  /* float: left; */
  display: flex;
}

.end-img {
  display: flex;
}

.circular-end {
  border: 1px solid;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: #feffe5;
  z-index: 222;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.plant-img {
  /* border: 1px solid; */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transform: translateX(-20%);
}

/* responsive site */


.service-card {
    max-width: 80%;
    height: 70vh;
    border-radius: 20px;
    text-align: center;
    background-color: #fed8cc;
    border: 2px solid black;
    /* margin-top: -6rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-20%);
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    align-items: center;
    scroll-behavior: smooth;
    transition-duration:  2s;
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;
  }
  
  .service-card::-webkit-scrollbar {
    display: none;
  }
  
  .title {
    color: #000;
    font-family: Raleway;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 44.8px */
    margin-top: 1.1em;
    text-align: start;
  }
  
  .description {
    color: #000;
    font-family: Raleway;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
    text-align: start;
  }
  
  .card-details {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* border: 1px solid; */
    scroll-snap-align: start;
    scroll-snap-stop: always;
    padding: 5rem;
    /* width: fit-content; */
  }
  
  
  .card-details:nth-child(2) {
    background-color: #c5f4f1;
  }
  .card-details:nth-child(3) {
    background-color: #cdf5ba;
  }
  .card-details:nth-child(4) {
    background-color: #ccd1fe;
  }
  .card-details:nth-child(5) {
    background-color: #feccd5;
  }
  
  .card-data {
    display: flex;
    width: 90%;
    overflow: hidden;
  }
  
  .title-and-desc {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 2em;
  }
  
  .details {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    margin: 1em;
  }
  
  .image-div {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }
  
  .image-div img {
    max-width: 80%;
    max-height: 80%;
    object-fit: cover;
    border-radius: .5em;
    
    /* transform: translate(20%); */
  }
  
  .detail-scroll {
    // height: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // position: absolute;
  }
  
  .detail-scroll h3 {
    font-size: 40px;
  }
  .start {
    // position: absolute;
    top: 25px;
  }
  
  .last {
    // position: absolute;
    bottom: 25px;
  }
  
  .visit {
    background-color: black;
    border-radius: 20px;
  }
  
  .line {
    height: 90%;
    width: 3px;
    background-color: white;
    border-radius: 10px;
    // position: absolute;
    // top: 15%;
    border-radius: 10px;
  }
  
  .check-now {
    border: none;
    border-radius: 10px;
    width: 8rem;
    height: 3rem;
    background-color: #000;
    color: #ddd;
    cursor: pointer;
    font-size: 1rem;
    position: absolute;
    margin-top: 8rem;
    margin-left: -25rem;
  }
  .mobile-visit {
    display: none;
  }
  
  
  /* responsive behaviour */
  
  @media (max-width: 850px) {
    .service-card {
      max-width: 90vw;
      height: 80vh;
      margin: 0 auto;
      border-radius: 20px;
      text-align: center;
      background-color: #fed8cc;
      display: flex;
      flex-direction: row;
      align-items: center;
      transform: translateY(-8%);
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
    }
  
    .card-details {
      width: 90vw;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column-reverse;
      padding: 0rem;

    }
  
    .detail-scroll {
      height: 0vh;
      width: 100%;
    }
  
    .line {
      height: 42vh;
      transform: rotate(-90deg);
      width: 4px;
      background-color: white;
      border-radius: 10px;
      position: absolute;
      border-radius: 10px;
      margin-top: -5rem;
    }
    .visit {
      display: none;
    }
  
    .mobile-visit {
      display: block;
      background-color: black;
      border-radius: 20px;
    }
  
    .start {
      display: none;
    }
  
    .last {
      display: none;
    }
  
    .card-data {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-evenly;
      align-items: center;
      scroll-snap-align: start;
    }
  
    .details {
 
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    
    .title-and-desc {
      margin-top: 0rem;
      width: 100%;
      height: 60%;
     
    }
    .title {
      font-size: 1rem;
    }
  
    .description {
      font-size: 0.8rem;
      margin: 2em;
      overflow: hidden;
    }


    .image-div {
      border-radius: 20px;
    }
    .image-div img {
      object-fit: cover;
      transform: translateX(0);
      border-radius: 20px;
    }
  
    .check-now {
      height: 3rem;
      align-self: center;
      position: absolute;
      margin-left: -15vw;
      bottom: 4rem;
    }
  
    .title-and-desc {
      display: flex;
      flex-direction: column;
      justify-content: CENTER;
    }

    .de-code {
      margin-top: 3rem;
    }
    .de-code h1 {
      margin: 1rem;
      font-size: 4.5rem;
    }
  }
  
}