.blog-area {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Raleway;
  position: relative;
  margin-top: 2rem;
}

.blog-desc p {
  font-size: 18px;
  text-align: center;
  position: relative;
}

.blog-cards-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 0rem;
  justify-content: center;
}

.blog-cards {
  padding: 0rem;
  border: 1px solid rgba(0, 0, 0, 0.3764705882);
  border-radius: 1em;
  width: 20vw;
  height: 25vw;
  cursor: pointer;
}

.blog-cards:hover {
  transform: scale(1);
  background-color: aliceblue;
}


.blog-img {
  width: 90%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-img img {
  max-width: 100%;
  margin: 1rem;
  border-radius: 20px;
}

.blog-title p {
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
}

.blog-data-desc {
  font-size: 14px;
  color: #919191;
}

.blog-data {
  width: 90%;
}

.views-comments {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.views-comments p {
  font-size: 14px;
  color: #817777;
}


/* responsive layouts */
@media (max-width: 850px) {
  .blog-area {
    flex-direction: column;
  }

  .blog-cards {
    width: 100%;
  }


  .blog-cards-container {
    display: flex;
    gap: 0rem;
    justify-content: center;
  }

  
.blog-cards {
  padding: 0rem;
  border: 1px solid rgba(0, 0, 0, 0.3764705882);
  border-radius: 1em;
  width: 90vw;
  height: 120vw;
  cursor: pointer;
}
}
