.OurCourses {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em;



    .courses-heading {
        margin: 1em;
    }

    .courses-para{
        margin: 1em 1.5em;
    }
    @media (max-width: 850px) {

    }
}