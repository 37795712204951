.partner-section {
  // height: 25vh;
  // margin: 5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 1rem;
  // max-height: 20rem;
}

.partner-section h1 {
  font-size: 1.5rem;
  font-weight: 900;

}

ul {
  list-style-type: none;
}

.marquee {
  --gap: 1rem;
  display: flex;
  overflow: hidden;
  gap: var(--gap);
  align-items: center;
  width: 100%;
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  gap: var(--gap);
  animation: scroll 20s linear infinite;
  animation-delay: -5s;
}

.marquee__content li {
  list-style: none;
}

.marquee__content img {
  max-width: 15vw;
  margin: 0 1.5em;
  @media (max-width: 768px) {
    max-width: 20vw;
    margin: 0 1em;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(1%);
  }

  100% {
    transform: translateX(-100%);
  }
}




