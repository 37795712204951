.bg {
    background: #000;
    padding: 2rem 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .career-detail {
    width: 70vw;
    border: 0.5px solid gray;
    border-radius: 8px;
    color: #e1e1e1;
    background: #171717;
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    line-height: 1.5;
  }
  .career-detail p {
    font-size: 1rem;
  }
  .career-heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .career-heading-section img {
    width: 80px;
    height: 80px;
  }
  .job-title {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  hr {
    width: 98%;
    margin: 0 auto;
  }
  
  .about-company {
    margin-top: 1.5rem;
  }
  .skill-chips {
    display: flex;
    flex-wrap: wrap;
  }
  .chip {
    margin-right: 1rem;
    background: #000;
    padding: 0.5rem;
    font-size: 0.8rem;
    border-radius: 8px;
  }
  @media (max-width: 768px) {
    .career-heading-section {
      flex-direction: column;
    }
    .job-title h2 {
      font-size: 1.4rem;
      text-align: left;
      width: 70%;
    }
    .career-detail p {
      font-size: 0.8rem;
    }
  }