.CourseCard {
    
  
    padding: 1rem;
    border: 1px solid #00000060;
    border-radius: 1em;
    width: 20vw;
    height: 25vw;
    cursor: pointer;
    &:hover {
        background-color: #fed8cc;
    }


    .course-name-detail {
        display: "flex";
        flexDirection: "column";
        // alignItems: "start";
        width: "100%";
        marginTop: "1rem";
        // border: "2px solid",
        align-items: center;
    }

    @media (max-width: 850px) {
        margin: 0em 2em;
        padding: 1rem;
        border: 1px solid #00000060;
        border-radius: 1em;
        width: 80vw;
        height: 100vw;
        cursor: pointer;
        &:hover {
            background-color: #fed8cc;
        }
    }
    

}