.award-card {
  position: relative;
  width: 20vw;
  height: 40vh;
  background-position: center;
  background-size: cover;
  border-radius: 0.8em;
  cursor: pointer;
  overflow: hidden;
  flex: 0 0 20vw;
  @media (max-width: 768px) {
    width: 90%;
    flex: 0 0 80vw;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  .card-content {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
      top 0.3s ease-in-out;
    width: 60%;

    p {
      color: #fff;
      font-size: 0.9rem;
      width: 100%;
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }

    .hover-image {
      max-width: 100%;
      transition: opacity 0.3s ease-in-out;
    }
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
    .card-content {
      top: 10%;
      opacity: 1;
    }
  }
}
