.Banner {
    .heart {
        position: absolute;
        right: 70px;
        top: -30px;
    }


    .coral-frame {
        position: absolute;
        background-color: #e87c61;
        width: 200px;
        height: 250px;
        border-radius: 20px;
        top: 26px;
        right: 198px;
        z-index: 2;
    }

    .skin-frame {
        background-color: #faf2be;
        border-radius: 20px;
        position: absolute;
        width: 165px;
        height: 205px;
        right: 33px;
        bottom: 0;
    }

    .about-images {
        width: 40%;
        position: relative;
    }

    .about-user-image {
        /* position: absolute; */
        border-radius: 20px;
        width: 250px;
        transform: translateY(40%);
        position: relative;
        z-index: 3;
    }

    .about-images img {
        max-width: 100%;
        height: 300px;
        object-fit: cover;
    }

    @media (max-width:850) {
        .about-images img {
            max-width: 90%;
            height: 300px;
            object-fit: cover;
            border-radius: 20px;
        }

        .about-user-image {
            left: -150px;
        }

        .social-links {
            gap: 0.5rem;
        }

        .coral-frame {
            top: 26px;
            right: 35px;
            z-index: 2;
        }

        /* .color-frame {
    position: absolute;
  } */

        .skin-frame {
            height: 205px;
            right: -130px;
            bottom: 0;
        }
    }
}