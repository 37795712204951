.HomePage {

  .award-section {
    width: 100%;
    h1 {
      font-weight: 600;
      margin-left: 1em;
      color: #00000060;
      span {
        color: #000;
      }
    }
    .award-container {
      padding: 2rem 0;
      display: flex;
      gap: 1rem;
      overflow-x: scroll;
      padding-left: 1em;
      @media (max-width: 768px) {
        overflow-x: scroll;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
