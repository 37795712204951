.PlacementStats {

    /* OurVision.css */

    .our-vision {
        display: flex;
        width: 100vw;
        /* padding: 99.997px 317.805px 100.003px 317.805px; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        background: #3fa959;
    }

    .vision-data {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 4rem;
    }

    .our-vision h2 {
        color: #fff;
        text-align: center;
        font-family: Raleway;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        /* 56px */
        margin: 0;
    }

    .our-vision p {
        color: #fff;
        width: 60%;
        text-align: center;
        font-family: Raleway;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        /* 30px */
    }

    .placement-stats>img {
        max-width: 150%;
        animation: statsRightToLeft 10s linear infinite alternate-reverse;
    }

    // @keyframes statsRightToLeft {
    //     form {
    //         right: 300;
    //     }

    //     to {
    //         transform: translateX(-20%);
    //     }
    // }

    .marquee {
        top: 0;
        right: 0;
        --gap: 1rem;
        position: absolute;
        display: flex;
        overflow: hidden;
        user-select: none;
        gap: var(--gap);
    }

    .marquee ul li {
        list-style-type: none;
    }

    .marquee__content {
        flex-shrink: 0;
        display: flex;
        justify-content: space-around;
        gap: var(--gap);
        // min-width: 80%;
        animation: scroll 20s linear infinite;
    }

    @keyframes scroll {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(calc(-100% - var(--gap)));
        }
    }

    .marquee-reverse {
        bottom: 0;
        left: 0;
        --gap: 1rem;
        position: absolute;
        display: flex;
        overflow: hidden;
        user-select: none;
        gap: var(--gap);
    }

    .marquee-reverse ul li {
        list-style-type: none;
    }

    .marquee__content-reverse {
        flex-shrink: 0;
        display: flex;
        justify-content: space-around;
        gap: 1rem;
        min-width: 80%;
        animation: scroll 20s linear infinite reverse;
    }

    .company-logos-two {
        height: 30%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        /* width:; */
        bottom: 0;
        left: 0;
        /* opacity: 0.3; */
        animation: companyLeftToRight 15s linear infinite;
        /* transform: translateY(20%); */
    }

    .underline {
        text-decoration: underline;
    }

    .placements-card {
        max-width: 85%;
        height: 50rem;
        border-radius: 20px;
        text-align: center;
        border: 2px solid black;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        margin: 1em 7.5%;
    }

    .statistics {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .statistics h2 {
        font-weight: 800;
        font-size: 2rem;
    }

    /* Alum container */
    .alumni-works-at {
        width: 100vw;
        background-color: #00000095;
        height: 350px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
    }

    .alum-container {
        background-color: rgba(0, 0, 0, 0.721);
        width: 30vw;
        text-align: center;
        border-radius: 20px;
    }

    .alum-container p {
        text-align: center;
        font-size: 1em;
        margin: 0.5em;
    }

    /* stats card */
    .offer-stats:nth-child(2) {
        position: fixed;
        bottom: 1.5rem;
        left: 16rem;
    }

    .offer-stats:nth-child(3) {
        position: fixed;
        top: 4rem;
        left: 27.5rem;
    }

    .offer-stats:nth-child(4) {
        position: fixed;
        top: 10rem;
        left: 47%;
    }

    .offer-stats:nth-child(5) {
        position: fixed;
        top: 4rem;
        right: 27rem;
    }

    .offer-stats:nth-child(6) {
        position: fixed;
        bottom: 1.5rem;
        right: 15rem;
    }

    .stats-cards {
        /* animation: wave 10s linear 1s infinite forwards; */
        transform: translateX(0rem);
    }

    /* program styyling */
    .program-card-container {
        width: 85%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        cursor: pointer;
    }



    /* responsive layouts */
    @media (max-width: 850px) {
        .home-title1 {
            width: 70%;
        }

        .home-title1 p {
            text-align: center;
        }

        .poster-img {
            object-fit: cover;
        }

        .paragraph {
            width: 90vw;
        }

        .vision-data p {
            width: 90vw;
        }

        .placement-stats>img {
            max-width: 200%;
            animation: statsRightToLeft 3s linear infinite alternate-reverse;
        }


        .statistics h2 {
            width: 95vw;
            text-align: center;
            font-size: 1.4em;

        }

        .placement-svg {
            width: 100%;
        }

        .placement-svg img {
            max-width: 100vw;
        }

        .statistics {
            margin: 2em 0em;
        }

        .statistics p {
            font-size: 1rem;
        }

        .program-card-container {
            justify-content: center;
        }

        .alum-container {
            background-color: #00000095;
            width: 90vw;
            text-align: center;
        }

        .alum-container p {
            text-align: center;
            font-size: .8em;
            margin: 0.5em;
        }

        .courses-heading {
            font-size: 1.5rem;
        }

        .courses-para {
            width: 90vw;
            font-size: 1rem;
        }

        .feedback-para {
            width: 90vw;
            font-size: 1rem;
        }
    }

}