.services-container {
  width: 100%;
  font-family: "Manrope", sans-serif;
}

.services-container{

    width:100vw;
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF4F0;
  }



.services-grid{
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
  justify-content: center;
  margin: 3em 0em;
}

.services-details {
  width: 100vw;
  position: relative;
  height: 400px;
  background-color: #3fa959;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.service-head {
  text-align: center;
  transform: translateY(-70%);
}

.service-head p {
  width: 300px;
  font-size: 1rem;
}

.student-img img {
  max-width: 15em;
}

.student-img {
  position: absolute;
  top: 4px;
  right: -181px;
}



/* Responsive layouts */
@media (max-width: 850px) {
  .services-details {
    overflow: hidden;
  }

  .student-img img {
    max-width: 12rem;
  }

  .student-img {
    position: absolute;
    top: 34px;
    right: -101px;
  }

  .services-grid{
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin: 3em 0em; */
}

}
