.HeroSection {
  /* CompanyInfo.css */

  .home-title1 p {
    font-size: 2.5rem;
    gap: 0.5rem;
    font-weight: 900;
    font-size: 52px;
    margin: 3rem;

  }

  .Heading{
  }

  .company-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .company-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #ddd;
  }

  .highlight {
    color: black;
  }

  .company-description {
    font-size: 1.5em;
    display: inline-block;
    text-align: center;
    font-family: Raleway;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Open Sans';
  }

  .company-description p {
    text-align: center;
    width: 70vw;
  }

  video {
    border-radius: 20px;
    /* border: 10px solid gray; */
    max-width: 100vw;
    height: 40rem;
    outline: 2px solid gray;
    outline-offset: 10px;
  }

  .home-title1 {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 40rem;
    overflow: hidden;
  }


  .poster-img {
    position: absolute;
    max-width: 100%;
    height: 50rem;
    z-index: -1;
    top: 0;
    object-fit: cover;
  }

  .home-title1 video {
    width: 4.2em;
    height: 2em;
    border: 2px solid black;
    border-radius: 2em;
  }

  .title2 {
    background-color: grey;
    display: flex;
    flex-direction: "row";
    justify-content: center;
    align-items: center;
  }

  .title2 p {
    text-align: center;
  }

  .homepage-paragraph {
    width: 100vw;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7f7fb;
  }

  .paragraph {
    width: 50vw;
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 100;
    line-height: 150%;
    /* 33px */
  }

  @media (max-width: 850px) {
    .home-title1 p {
      font-size: 1.3rem;
      gap: 0.5rem;
    }

    .company-description p {
      font-size: 1rem;
      width: 80vw;
      line-height: 1.2;
    }

    .paragraph {
      width: 90vw;
      color: #000;
      text-align: center;
      font-family: Raleway;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 100;
      line-height: 150%;
      /* 33px */
    }

    .company-video {
      width: 90%;
      background-color: rgb(12, 12, 12);
      border-radius: 20px;
      margin-top: 2rem;
    }

    .company-video video {
      border-radius: 20px;
      width: 100%;
      /* height: 30vh; */
      height: 30vh;
    }

    .title-para-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
