.program-container {
  /* position: relative; */
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.program-container h2,
p {
  text-align: center;
}

form {
  color: #555;
  display: flex;
  padding: 10px;
  border: 1px solid currentColor;
  border-radius: 24px;
  width: 60vw;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  width: 100%;
}


input[type="search"]::placeholder {
  color: #bbb;
}

.submitButton[type="submit"] {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  cursor: pointer;
  opacity: 0.7;
}

.submitButton[type="submit"]:hover {
  opacity: 1;
}

.submitButton[type="submit"]:focus,
input[type="search"]:focus {
  box-shadow: 0 0 3px 0 #1183d6;
  border-color: #1183d6;
  outline: none;
}

.Shortcuts {
  margin-top: 2rem;
  display: flex;
  overflow-x: scroll;
  align-items: center;
}

.select-shortcut {
  margin: 0.2rem;
}

.Shortcuts::-webkit-scrollbar{
  width: 0;
}




.programGrid {
  width: 100%;
  margin: 3rem 0;
}

.program-card-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1rem;
  justify-content: center;
}



.girl-svg1 img {
  width: 10%;
  position: absolute;
  top: 5em;
  /* left: -43px; */
}

.bulb img {
  position: absolute;               
  top: 4em;
  right: 40%;
}



@media (max-width: 850px) {
  .Shortcuts {
    width: 80vw;
    display: flex;
  }


  form {
    color: #555;
    display: flex;
    padding: 2px;
    border: 1px solid currentColor;
    width: 80vw;
  }

  .program-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
