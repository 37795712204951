.AboutUsDetails{
    .about-details {
        margin: 4rem 0;
        width: 70%;
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 3rem;
        position: relative;
        @media (max-width: 768px) {
          display: inline;
          margin: 3em;
        }
      }
      
      .about-details p {
        margin: 0;
        font-size: 14px;
        line-height: 25px;
      }
      
      .details-head h3 {
        font-size: 16px;
        padding-top: 10px;
        @media (max-width: 768px) {
          margin: 3em 0em 1em 0em;
        }
      }
      
      .computer-guy img {
        position: absolute;
        right: -22%;
        bottom: 50%;
      }
}