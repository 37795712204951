
.search-bar{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
form {
  /* color: #db2727; */
  display: flex;
  padding: 2px;
  /* border: 1px solid currentColor; */
  border-radius: 20px;
  width: 100%;

}


.search-btn {
  width: 40vw;
  height: 60px;
  border-radius: 20px;
}

.search-btn button{
  cursor: pointer;
}

input[type="search"] {
  height: 100%;
  font-size: 1.25rem;
  padding: 10px;
}

button[type="submit"] {
  width: 20vw;
  border-radius: 20px;
  border: none;
  background-color: black;
  color: white;
}


.all-courses-btn button {
  margin: 2rem 0;
  border: none;
  width: 9rem;
  height: 3rem;
  border-radius: 10px;
  background-color: black;
  color: white;
  cursor: pointer;
}

.all-blogs-btn {
  margin: 2rem 0;
  border: none;
  width: 10rem;
  height: 2.7rem;
  border-radius: 10px;
  background-color: black;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* text-align: center; */
}



.clickable-btn {
  width: 7em;
  height: 3em;
  border-radius: 10px;
  border: none;
  font-size: 12px;
  color: white;
}

.clickable-btn:hover{
  cursor: pointer;
}



/* responsive layout */
@media (max-width: 850px) {

  form {
    /* color: #db2727; */
    display: flex;
    padding: 2px;
    /* border: 1px solid currentColor; */
    border-radius: 20px;
    width: 90%;
  
  }
  .search-btn {
    width: 90vw;
    height: 60px;
    border-radius: 20px;
  }
  
  button[type="submit"] {
    width: 50vw;
    border-radius: 20px;
    border: none;
    background-color: black;
    color: white;
  }

  .all-courses-btn button{
    height: 3rem;
  }
  

  .all-blogs-btn{
    display: none;
  }

  .clickable-btn {
    width: 10em;
    height: 4em;
    border-radius: 10px;
    border: none;
    font-size: 12px;
    color: white;
  }
  
  .green button {
    background-color: #3fa959;
    color: white;
    margin: 2rem 0;
    border: none;
    width: 6rem;
    height: 2.8rem;
    border-radius: 10px;
  }

}