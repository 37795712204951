
.blogs-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}


.blog-head{
    border: 1px solid;
    text-align: center;
}

.blog-head p{
    text-align: center;
}

.blog-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .blog-links{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .blog-desc p {
    font-size: 18px;
    text-align: center;
    position: relative;
  }
  
  .blog-cards-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .blog-cards {
    border: 1px solid;
    border-radius: 20px;
    width: 22%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 1rem 0 2rem 0;
    transition: all 0.3s linear;
    margin: 2em;
  }
  
  .blog-cards:hover {
    transform: scale(1.1);
    background-color: aliceblue;
  }
  
  .blog-square-img {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blog-square-img img {
    max-width: 100%;
    margin: 1rem;
    object-fit: "cover";
    border-radius: 20px;
  }
  
  .blog-title p {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
    color: black;
  }
  
  .blog-data-desc {
    font-size: 14px;
    color: #919191;
  }
  
  .blog-data {
    width: 90%;
  }
  
  .views-comments {
    width: 80%;
    display: flex;
    justify-content: space-between;
  }
  .views-comments p {
    font-size: 14px;
    color: #817777;
  }
  
  .blog-globe {
    position: absolute;
    top: 58px;
    right: 381px;
    transform: rotate(-13deg);
  }
  