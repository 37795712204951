.mobile-maintainance{
    height: 90vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.head-and-para{
    width: 70vw;
    text-align: center;
}

.head-and-para h3{
    font-size: 1.5rem;
}

.head-and-para p{
    text-align: center;
    font-size: .9rem;
}

.downlaod-btn{
    display: flex;
    gap: .4rem;
    width: 12rem;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    background-color: black;
    color: white;
}