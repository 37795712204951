.text-on-image {
  width: 100%;
  /* height: 100%; */
  color: white;
  position: sticky;
  bottom: 0;
  padding-left: 10px;
  padding-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.644);
  box-shadow: rgba(0, 0, 0, 0.644) 7px 0px 10px 19px;
  font-family: "Raleway", sans-serif;
}

.text-on-image h1 {
  font-size: 3rem;
}

.text-on-image h3 {
  font-size: 0.9rem;
  font-weight: 100;
}

.text-on-image h1,
h3 {
  margin: 0;
}

.single-card {
  margin: 5px;
  border: 1px solid black;
  width: 20rem;
  height: 20rem;
  border-radius: 20px;
  overflow: hidden;
  background-color: #3fa959;
  color: white;
  cursor: pointer;
  float: left;
  z-index: 555;
}

.single-card p {
  margin: 0px;
  font-size: 14px;
  padding: 1rem;
}

.head-text {
  position: relative;
  /* width: 100%;
  height: 50vh; */
}

.head-text img {
  max-width: 100%;
  height: 20rem;
  object-fit: cover;
}


/* Services Card */
.services-card {
  /* border: 2px solid; */
  display: none;
}

.services-columns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 60vw;
  margin-top: -10rem;
}


.typewriter-text {
  overflow: hidden;
  white-space: pre-line;
  max-height: 1rem;
  animation: typing 12s steps(30, end) forwards, blink-caret 0.5s step-end infinite;
}

@keyframes typing {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 1000px;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}



/* responsive layouts */
@media (max-width: 850px) {
  

  .single-card {
    margin: 0px;
    border: 1px solid black;
    width: 80vw;
    height: 50vh;
    border-radius: 20px;
    overflow: hidden;
    background-color: #3fa959;
    color: white;
    cursor: pointer;
    float: left;
  }

  .single-card p {
    padding: 1rem;
  }
  .head-text img {
    width: 100%;
    height: 40vh;
  }


 
}
