.career-head-para {
  text-align: center;
  font-size: 1.3rem;
  /* textAlign: "center", fontSize: "1.3rem" */
}

/* Company Images CSS */

.company-images {
  /* width: 80vw; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

.first-images-container {
  display: flex;
  gap: 1em;
}

.first-images-container img{
  height: 100%;
  object-fit: cover;
}

.first-image img {
  border-radius: 20px;
}

.first-images {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.first-images img {
  border-radius: 20px;
}

.first-one {
}

.second-images-container {
  display: flex;
  gap: 1em;
}



.second-images {
  display: flex;
  flex-direction: column;
  gap: 1em;
}



.first-two {
}

.second-image img {
  object-fit: cover;
  height: 100%;  border-radius: 20px;
}

.second-images-1 {
  border-radius: 20px;
}

.second-images-2 {
  height: 58%;
  object-fit: cover;
  border-radius: 20px;
}

/* job card */

.job-card {
  width: 80vw;
  margin: 0 0 5rem 0;
}

.jobCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0;
  border-radius: 20px;
  background-color: #2d2d2d;
  position: relative;
  overflow: hidden;
}

.single-job-card {
  width: 75vw;
  margin-top: 1rem;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: start;
}

.single-job-card ul {
  width: 70vw;
  text-align: start;
  align-self: flex-start;
}

.view-apply-btn {
  width: 20%;
  /* margin-right: 2rem; */
  /* width: 180px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.jobs-requirements {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.single-job-card ul {
  color: #919191;
  text-align: start;
  padding-left: 1em;
  align-self: flex-start;
}

.single-job-card ul li {
  font-size: 0.9rem;
  margin-bottom: 1em;
}

/* lamp */

.lamp {
  position: absolute;
  bottom: -65px;
  right: -107px;
}

.brain {
  position: absolute;
  right: -78px;
  top: 126px;
}

.pen {
  position: absolute;
  top: 341px;
  left: -68px;
}

.mail-envelope {
  position: absolute;
  bottom: 135px;
  right: -78px;
}

.notes {
  position: absolute;
  bottom: -70px;
  left: -105px;
}

.cmpny-img-and-job{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.job-card-conainter{
  width:80vw;
  border: 1px solid;
}

/* responsive layouts */

@media (max-width: 850px) {
  .career-head-para {
    font-size: 0.9rem;
  }

  .company-images {
    overflow: hidden;
    width: 90%;
    display: flex;
    justify-content: center;
  }

  .first-image img {
    height: 100%;
  }

  .second-images-container {
    display: none;
  }

  .job-title {
    text-align: center;
  }
  .single-job-card {
    width: 80vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .view-apply-btn {
    width: 80%;
    justify-content: space-between;
  }

  .jobs-requirements {
    width: 80%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .single-job-card ul {
    /* padding-left: 3em; */
  }

  .single-job-card ul li {
    font-size: 0.8em;
  }

  .lamp {
    position: absolute;
    bottom: -65px;
    right: -21px;
  }

  .lamp img {
    max-width: 7em;
  }
}
