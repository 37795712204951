.Mentorship{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
    .slide img {
        width: 20rem;
        margin: 0 auto;
      }
      
      .slide {
        transform: scale(0.2);
        transition: transform 300ms;
        opacity: 0.5;
      }
      
      .activeSlide {
        transform: scale(1.1);
        opacity: 1;
      }
      
      .mentorship-course-container{
        display: flex;
        justify-content: center;
        align-content: center;
      }
     
      .mentors-detail{
        display: flex;
        margin-top: 2em;
      }
      
      .course-offer {
        width: 60%;
        margin: 10rem 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-family: "Raleway";
      }
      
      .course-card-conatiner {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      
  
      
      .card1 {
        position: absolute;
        transform: rotate(25deg);
        border: 1px solid black;
        border-radius: 20px;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: #ffffff7b;
        color: rgb(0, 0, 0);
        box-shadow: 2px 3px 5px black;
        z-index: -4;
        top: 5rem;
        right: 2rem;
      }
      .card2 {
        position: absolute;
        transform: rotate(-10deg);
        border: 1px solid black;
        border-radius: 20px;
        width: 40%;
        /* height: 50%; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: #ffffffbe;
        color: rgb(0, 0, 0);
        box-shadow: 2px 3px 5px black;
        z-index: -3;
        top: -5rem;
        left: 4rem;
      }
      .card3 {
        position: absolute;
        transform: rotate(-35deg);
        border: 1px solid black;
        border-radius: 20px;
        width: 40%;
        /* height: 50%; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        box-shadow: 2px 3px 5px black;
        z-index: -3;
        top: 5rem;
        left: 1rem;
      }
      
      .course-card img {
        margin-bottom: 1rem;
        width: 90%;
        height: 80%;
        object-fit: cover;
      }
      
      .course-card p {
        width: 80%;
      }
      
      .course-offer-desc {
        width: 45%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-content: start;
      }
      
      .course-offer-desc p {
        line-height: 20px;
      }
      
      .btn {
        margin: 1rem;
      border: none;
      width: 12rem;
      height: 4rem;
      border-radius: 10px;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-align: center;
      }
      @media (max-width : 850px) {
      
        .mentorship-course-container{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
        }
        .mentors-detail{
          display: flex;
          flex-direction: column;
        }
      
        .course-offer {
          width: 100%;
          margin: 10rem;
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-family: "Raleway";
        }
      
        .course-offer-desc {
          width: 100%;
        }
        
        .course-card-conatiner {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-top: 5em;
        }
      
        .btn {
          margin: 1rem;
          border: none;
          width: 12rem;
          height: 4rem;
          border-radius: 10px;
          background-color: black;
        
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          /* text-align: center; */
        }
     
        
      }
      
      
      
      /* @media (max-width : 400px) {
        .course-offer {
          /* width: 80vw; */
      /* height: 50rem;
          margin: 4rem 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          font-family: "Raleway";
        } */
      
      /* } */
      
}