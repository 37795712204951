.TestimonialsCard{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
/* testimonial css */
.testimonials {
  width: 95%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 4rem 0;
  font-family: "raleway";
}

.feedback-para {
  width: 50%;
  text-align: start;
}

.testimonial-user-data {
  width: 80%;
  display: flex;
  justify-content: center;
  // padding-bottom: 20px;
  margin: 3rem 0;
}

.user-image {
  width: 30%;
  border-radius: 20px;
  margin-right: 5em;
}

.user-image img {
  object-fit: cover;
  height: 80%;
  max-width: 90%;
  border-radius: 20px;
  padding: 0.5rem;
  border: 1px solid;
}

.user-testimonial-edu {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  text-align: start;
}

.courses-heading {
  text-align: center;
  font-weight: 500;
  font-size: 2rem;
  margin: 0;
}

.user-testimonial {
  position: relative;
}

.user-testimonial p {
  width: 80%;
  height: 10vh;
  font-size: 0.9rem;
  line-height: 22px;
}

.courses-para {
  text-align: center;
  margin-bottom: 4rem;
}

.quotes {
  position: absolute;
  top: -15px;
  left: -40px;
}

.quotes img {
  margin: 0;
  // width: 30px;
  // height: 50px;
}

.testi {
  text-transform: uppercase;
  position: absolute;
  transform: rotate(-90deg);
  top: 30rem;
  scroll-behavior: smooth;
}

.testi a {
  text-decoration: none;
  color: #7c7c7c;
  letter-spacing: 3px;
}

.user-name-edu {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}

.other-testimonials {
  display: flex;
  gap: 12px;
  padding: 0.5rem 0;
  /* grid-template-columns: repeat(4, 1fr); */
}
.other-testimonials-image {
  width: 60px;
  height: 80px;
}

.testimonials-image-selected img{
  border: rgb(0, 119, 255) 1px solid;
  border-radius: 8px;
}

.other-testimonials img {
  object-fit: fill;
  overflow: hidden;
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid gray;
}

.other-testimonials-image:hover {
  cursor: pointer;
}

.arrow {
  position: absolute;
  width: 2px;
  height: 250px;
  background-color: #7c7c7c;
  top: -115px;
  left: -130px;
  transform: rotate(90deg);
}

.arrow1 {
  position: absolute;
  width: 2px;
  height: 10px;
  background-color: #7c7c7c;
  left: -252px;
  top: 2px;
  transform: rotate(45deg);
}
.arrow2 {
  position: absolute;
  width: 2px;
  height: 10px;
  background-color: #7c7c7c;
  left: -252px;
  top: 8px;
  transform: rotate(135deg);
}

@media (max-width:850px){
  
  .testimonial-user-data {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }

  .user-image {
    width: 80vw;
    margin-right: 0em;
  }

  .user-image img {
    height: 50vh;
    object-fit: cover;
    padding: 0.5rem;
    border-radius: 40px;
  }

  .user-testimonial-edu {
    width: 90vw;
  }

  .user-testimonial-edu {
    width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    text-align: start;
  }

  .user-testimonial p {
    width: inherit;
    height: auto;
  }
  .feedback-para {
    width: 100%;
    text-align: start;
  }
  .user-position-and-company {
    width: 70vw;
  }

}

}