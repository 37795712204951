/* Footer.css */

.footer {
  width: 100vw;
  background-color: #191919;
  color: #7e7e7e;
  text-align: center;
}

.footer-content {
  max-width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
}

.footer-logo {
  width: 250px;
}

.footer-logo img {
  max-width: 13vw;
}
.company-address {
  display: grid;
  grid-template-rows: repeat(2, 0.5fr);
  align-items: center;
  width: 300px;
  gap: 0.5em;
}

.company-address p {
  margin: 0;
}

.address-one {
  font-size: 16px;
}

.address-two {
  font-size: 16px;
  text-align: start;
}

.address-two a {
  color: #7e7e7e;
  text-decoration: underline;
}

.pages {
  width: 250px;
}

.pages ul {
  list-style: none;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  text-align: start;
  gap: 0.8em;
  cursor: pointer;
}

.footer-pages {
  color: #7e7e7e;
}

.footer-socials-links {
  display: grid;
  grid-template-rows: 0.1fr 1fr;
  width: 200px;
}

.socials-links {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  cursor: pointer;
}

.socials-links i {
  width: 20px;
  height: 20px;
  padding: 5px;
  background-color: #7e7e7e;
  color: #191919;
  border-radius: 10px;
  font-size: 20px;
}

.socials-links a {
  width: 20px;
  height: 20px;
  padding: 5px;
  background-color: #7e7e7e;
  color: #191919;
  border-radius: 10px;
  font-size: 20px;
}

.footer-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

@media (max-width: 850px) {
  .footer {
    width: 100vw;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .pages ul {
    padding: 0;
  }

  .footer-logo {
    text-align: start;
    transform: translateX(-6%);
    margin: 1rem;
  }

.footer-logo img {
  max-width: 50vw;
}
}
