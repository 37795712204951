.TeamMembersCard {

    /* Our Team */
    .our-teams {
        width: 100%;
        background-color: black;
        // background-image: url(/public/images/background-design.svg);
        background-repeat: repeat;
        background-size: cover;
        /* Use "cover" to scale and cover the entire container */
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .our-team {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: start;
        font-family: "Raleway", sans-serif;
        /* align-self: flex-start; */
    }

    .our-team-container {
        /* border: 1px solid white; */
        width: 20%;
        color: white;
        /* margin-top: -15rem; */
        margin-left: 3rem;
        transform: translateY(20%);

        @media (max-width: 768px) {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }

    .team-members-container {
        width: 70%;
        /* border: 1px solid white; */
        /* overflow: hidden; */
    }

    .team-member {
        /* margin-top: 2rem; */
        color: white;
        display: grid;
        grid-template-columns: 1fr 2fr 0.5fr;
        align-items: center;
        margin: 2rem 0;
        padding: 1rem 0;
        border-radius: 20px;

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .member-image {
        grid-row-start: 1;
        grid-row-end: 3;
        display: flex;
        align-self: start;
        justify-content: center;

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-self: center;
        }
    }

    .member-image img {
        max-width: 8em;
        border-radius: 20px;
    }

    .member-name {
        font-size: 14px;
    }

    .designation {
        color: #ffffff;
        font-size: 12px;
    }

    .member-intro {
        grid-column-start: 2;
        grid-column-end: 4;
        color: #919191;
    }

    .member-intro p {
        font-size: 14px;
    }

    .social-links {
        width: 70%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;

        @media (max-width: 768px) {
            justify-content: center;

        }
    }

    .social-links a {
        color: #919191;
        font-size: 20px;
        margin: 1em;
    }

    .team-member:hover {
        background-color: #62cc7c8a;
    }


    @media (max-width: 850px) {
        .our-team {
            display: flex;
            flex-direction: column;
        }

        .our-team-container {
            /* border: 1px solid white; */
            width: 80vw;
            color: white;
            /* margin-top: -15rem; */
            margin-left: 3rem;
            transform: translateX(-15%);
        }

        .team-member {
            gap: 1rem;
        }

        .team-members-container {
            width: 85vw;
        }
    }
}