.bytecoder-login-logo {
  transform: translateX(-10%);
}

.bytecoder-login-logo img{
  width: 80%;
}

.login-left-container {
  width: 30%;
  margin-top: 4em;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.login-intro h3 {
  font-weight: 100;
  font-size: 2rem;
}

.login-intro p {
  font-size: 0.9rem;
}

.login-svg-img {
  align-self: center;
}

.login-svg-img img {
  max-width: 20em;
}

/* Responsive layouts */

@media (max-width: 850px) {
  .login-left-container {
    align-self: start;
    margin-left: 3rem;
    height: 10vh;
  }

  .login-intro,
  .login-svg-img {
    display: none;
  }

  .bytecoder-login-logo {
    transform: translateX(-15%);
  }
  .bytecoder-login-logo img{
    width: 10em;
  }
  
}
