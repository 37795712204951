.about-container {
  font-family: "Raleway", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-head {
  width: 100%;
  height: 450px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.about {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.about h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  z-index: 1;
}

.about p {
  font-weight: 100;
}

.about h2,
p {
  text-align: start;
}

.custom-underline {
  position: relative;
  text-decoration: none;
  display: inline-block;
}

.custom-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px; 
  width: 100%;
  height: 2px;
  background-color: #3fa959; 

}

@media (max-width: 850px) {
  .about-head {
    height: 60vh;
    display: flex;
    flex-direction: column;
  }

  .about-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }


}

 

