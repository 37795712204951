:root {
  --cards: 3;
  /* --cardHeight: 100vh; */
  --cardTopPadding: 1em;
  --cardMargin: 4vw;
}

.de-code {
  color: #f2f2f2;
  font-size: 4rem;
  font-weight: 700;
  opacity: 0;
  transform: scale(0);
  transition: transform 2s linear;
  transition-delay: 0.2s;
  text-align: center;
  margin-top: 2rem;
}

.de-code h1 {
  margin: 0;
}

.animate {
  opacity: 1;
  transform: scale(1);
}
.carousel-container {
  width: 80%;
  height: 80%;
  margin: 0 auto;
}

#banners {
  list-style: none;
  padding-left: 0;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--cards), var(--cardHeight));
  gap: var(--cardMargin);
  padding-bottom: calc(var(--cards) * var(--cardTopPadding));
  margin-bottom: var(--cardMargin);
}

#card1 {
  --index: 1;
}
#card2 {
  --index: 2;

}
#card3 {
  --index: 3;
}
#card4 {
  --index: 4;
}
#card5 {
  --index: 5;
  
}

.banner {
  position: sticky;
  top: 0;
  padding-top: calc(var(--index) * var(--cardTopPadding));
}

.card-body {
  border-radius: 0.5em 0.5em 7rem 0.5em;
  /* box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3); */
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  padding: 2rem;
  gap: 0.8rem;
  height: 100%;
}
.card-body p {
  line-height: 1.5;
}
.card-body img {
  width: 50%;
  border-radius: inherit;
}

@media (max-width: 768px) {
  :root {
    --cardTopPadding: 0.4em;
    /* --cardHeight: 100vh; */
    --cardMargin: 12vw;
  }
  .de-code {
    margin-top: 3rem;
  }
  .de-code h1 {
    margin: 0;
    font-size: 4.5rem;
  }
  .carousel-container {
    width: 90%;
  }
  .card-body {
    border-radius: 0.5em 0.5em 1em 0.5em;
    flex-direction: column-reverse;
    gap: 0.2rem;
    padding: 1rem;
  }
  .card-body img {
    height: 40%;
  }
}
